// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
// Modules
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgbDropdownModule, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from "../shared/shared.module";
import { NgxPermissionsModule } from 'ngx-permissions';
import { CoreModule } from 'src/app/core/core.module';
// Routing
import { Routing } from './routing';
// Components
import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './components/header/header.component';
import { ContentComponent } from './components/content/content.component';
import { FooterComponent } from './components/footer/footer.component';
import { ScriptsInitComponent } from './components/scripts-init/scripts-init.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarLogoComponent } from './components/sidebar/sidebar-logo/sidebar-logo.component';
import { SidebarMenuComponent } from './components/sidebar/sidebar-menu/sidebar-menu.component';
import { SidebarFooterComponent } from './components/sidebar/sidebar-footer/sidebar-footer.component';
import { UserInfoComponent } from './components/sidebar/user-info/user-info.component';
import { ThemeModeSwitcherComponent } from './components/sidebar/theme-mode-switcher/theme-mode-switcher.component';
import { ImpersonationModeBannerComponent } from './components/impersonation-mode-banner/impersonation-mode-banner.component';
// Transloco Module
import { TranslocoRootModule } from 'src/app/transloco-root.module';
// Lodingbar Module:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';

// Routes
const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: Routing,
  },
];

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    ContentComponent,
    FooterComponent,
    ScriptsInitComponent,
    UserInfoComponent,
    ThemeModeSwitcherComponent,
    SidebarComponent,
    SidebarLogoComponent,
    SidebarMenuComponent,
    SidebarFooterComponent,
    ImpersonationModeBannerComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    InlineSVGModule,
    NgbDropdownModule,
    NgbProgressbarModule,
    NgbTooltipModule,
    SharedModule,
    NgxPermissionsModule,
    TranslocoRootModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    CoreModule
  ],
  exports: [RouterModule],
})
export class LayoutModule { }
