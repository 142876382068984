// Angular
import { Injectable } from '@angular/core';
// Rxjs
import { BehaviorSubject } from 'rxjs';

export interface PageLink {
  title: string;
  path: string;
  isActive: boolean;
  isSeparator?: boolean;
}

export class PageInfo {
  breadcrumbs: Array<PageLink> = [];
  title: string = '';
}

@Injectable({
  providedIn: 'root',
})
export class PageInfoService {

  // BehaviorSubjects to hold dynamic page information: title, description, and breadcrumbs
  public title: BehaviorSubject<string> = new BehaviorSubject<string>('Dashboard');
  public description: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public breadcrumbs: BehaviorSubject<Array<PageLink>> = new BehaviorSubject<Array<PageLink>>([]);

  /**
   * Creates an instance of PageInfoService.
   * @memberof PageInfoService
   */
  constructor() { }

  /**
   * Sets the page title and emits the new value to observers.
   * @param _title - The new title to set.
   */
  public setTitle(_title: string) {
    this.title.next(_title);
  }

  /**
   * Updates the title with a slight delay to ensure asynchronous operations are handled.
   * @param _title - The title to update.
   */
  public updateTitle(_title: string) {
    setTimeout(() => {
      this.setTitle(_title);
    }, 1);
  }

  /**
   * Sets the page description and emits the new value to observers.
   * @param _description - The new description to set.
   */
  public setDescription(_description: string) {
    this.description.next(_description);
  }

  /**
   * Updates the description with a slight delay to ensure asynchronous operations are handled.
   * @param _description - The description to update.
   */
  public updateDescription(_description: string) {
    setTimeout(() => {
      this.setDescription(_description);
    }, 1);
  }

  /**
   * Sets the breadcrumb list and emits the new value to observers.
   * @param _bs - The array of breadcrumbs to set.
   */
  public setBreadcrumbs(_bs: Array<PageLink>) {
    this.breadcrumbs.next(_bs);
  }

  /**
   * Updates the breadcrumb list with a slight delay to ensure asynchronous operations are handled.
   * @param _bs - The breadcrumbs to update.
   */
  public updateBreadcrumbs(_bs: Array<PageLink>) {
    setTimeout(() => {
      this.setBreadcrumbs(_bs);
    }, 20);
  }

  /**
   * Calculates and sets the title based on active menu items in the sidebar or header.
   * It tries to find the title from the sidebar first, then from the header.
   */
  public calculateTitle() {
    const asideTitle = this.calculateTitleInMenu('kt_app_sidebar');
    const headerTitle = this.calculateTitleInMenu('kt_app_header_wrapper');
    const title = asideTitle || headerTitle || '';
    this.setTitle(title);
  }

  /**
   * Extracts the active title from the given menu by its ID.
   * @param menuId - The ID of the menu to search.
   * @returns The title of the active menu link, if found.
   */
  public calculateTitleInMenu(menuId: string): string | undefined {
    const menu = document.getElementById(menuId);
    if (!menu) return;

    const allActiveMenuLinks = Array.from<HTMLLinkElement>(
      menu.querySelectorAll('a.menu-link')
    ).filter((link) => link.classList.contains('active'));

    if (!allActiveMenuLinks || allActiveMenuLinks.length === 0) return;

    const titleSpan = allActiveMenuLinks[0].querySelector('span.menu-title') as HTMLSpanElement | null;
    if (!titleSpan) return;

    return titleSpan.innerText;
  }

  /**
   * Calculates and sets the breadcrumbs based on active menu items.
   * It tries to calculate from the sidebar first, then from the header.
   */
  public calculateBreadcrumbs() {
    const asideBc = this.calculateBreadcrumbsInMenu('kt_app_sidebar');
    const headerBc = this.calculateBreadcrumbsInMenu('kt_app_header_wrapper');
    const bc = asideBc && asideBc.length > 0 ? asideBc : headerBc;

    if (!bc) {
      this.setBreadcrumbs([]);
      return;
    }
    this.setBreadcrumbs(bc);
  }

  /**
   * Extracts the breadcrumbs from the active items in the given menu by its ID.
   * @param menuId - The ID of the menu to search.
   * @returns An array of PageLink objects representing the breadcrumbs.
   */
  public calculateBreadcrumbsInMenu(menuId: string): Array<PageLink> | undefined {
    const result: Array<PageLink> = [];
    const menu = document.getElementById(menuId);
    if (!menu) return;

    const allActiveParents = Array.from<HTMLDivElement>(
      menu.querySelectorAll('div.menu-item')
    ).filter((link) => link.classList.contains('here'));

    if (!allActiveParents || allActiveParents.length === 0) return;

    allActiveParents.forEach((parent) => {
      const titleSpan = parent.querySelector('span.menu-title') as HTMLSpanElement | null;
      if (!titleSpan) return;

      const title = titleSpan.innerText;
      const path = titleSpan.getAttribute('data-link');
      if (!path) return;

      result.push({
        title,
        path,
        isSeparator: false,
        isActive: false,
      });

      // Add separator between breadcrumbs
      result.push({
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      });
    });

    return result;
  }
}
