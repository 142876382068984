<!-- begin:: Banner -->
<app-impersonation-mode-banner />
<!-- end:: Banner -->

<!--begin::App-->
<div class="d-flex flex-column flex-root app-root" id="kt_app_root">
  <!--begin::Page-->
  <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
    <!--begin::Header-->
    <app-header></app-header>
    <!--end::Header-->
    <!--begin::Wrapper-->
    <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">

      <!--begin::sidebar-->
      <app-sidebar #ktSidebar id="kt_app_sidebar" class="app-sidebar flex-column">
      </app-sidebar>
      <!--end::sidebar-->

      <!--begin::Main-->
      <div class="app-main flex-column flex-row-fluid" id="kt_app_main">
        <!--begin::Content wrapper-->
        <div class="d-flex flex-column flex-column-fluid my-5">
          <app-content id="kt_app_content" class="app-content">
          </app-content>
        </div>
        <!--end::Content wrapper-->

        <!-- begin:: Footer -->
        <app-footer class="app-footer"></app-footer>
        <!-- end:: Footer -->

      </div>
      <!--end:::Main-->
    </div>
    <!--end::Wrapper-->

  </div>
  <!--end::Page-->
</div>
<!--end::App-->

<app-scripts-init></app-scripts-init>