// Angular
import { Injectable } from '@angular/core';
// Rxjs
import { BehaviorSubject } from 'rxjs';
// Config
import { ILayout, LayoutType } from './configs/config';
// Service
import { LayoutService } from './layout.service';

@Injectable({
  providedIn: 'root',
})
export class LayoutInitService {

  // Private
  private config = new BehaviorSubject<ILayout | null>(null);

  /**
   * Creates an instance of LayoutInitService.
   * @param {LayoutService} layout
   * @memberof LayoutInitService
   */
  constructor(private readonly layout: LayoutService) { }

  /**
   * Re-initializes layout properties
   * @param {LayoutType} [layoutType]
   * @memberof LayoutInitService
   */
  reInitProps(layoutType?: LayoutType): void {
    this.layout.reInitProps();

    const currentLayoutType = layoutType || this.layout.getBaseLayoutTypeFromRouteOrLocalStorage();
    const config = this.layout.getLayoutConfig(currentLayoutType);

    this.layout.currentLayoutTypeSubject.next(currentLayoutType);
    this.config.next({ ...config });

    // Initialize base layout and width settings
    this.initLayoutSettings(currentLayoutType, config);
    this.initWidthSettings(config);

    this.layout.layoutConfigSubject.next({ ...this.config.value });
  }

  /**
   * Sets the base layout type and re-initializes layout properties
   * @param {LayoutType} layoutType
   * @memberof LayoutInitService
   */
  setBaseLayoutType(layoutType: LayoutType): void {
    this.layout.setBaseLayoutType(layoutType);
    this.reInitProps(layoutType);
  }

  /**
   * Initializes layout-specific settings (e.g., CSS classes and attributes)
   * @private
   * @param {LayoutType} layoutType
   * @param {ILayout} config
   * @memberof LayoutInitService
   */
  private initLayoutSettings(layoutType: LayoutType, config: ILayout): void {
    this.clearBodyClasses();
    this.clearBodyAttributes();

    document.body.setAttribute('style', '');
    document.body.setAttribute('id', 'kt_app_body');
    document.body.setAttribute('data-kt-app-layout', layoutType);
    document.body.setAttribute('data-kt-name', 'prime');
    document.body.classList.add('app-default');
  }

  /**
   * Clears all body classes
   * @private
   * @memberof LayoutInitService
   */
  private clearBodyClasses(): void {
    const bodyClasses = document.body.classList.value.split(' ');
    bodyClasses.forEach((cssClass) => document.body.classList.remove(cssClass));
  }

  /**
   * Clears all body attributes starting with 'data-'
   * @private
   * @memberof LayoutInitService
   */
  private clearBodyAttributes(): void {
    const bodyAttributes = document.body
      .getAttributeNames()
      .filter((attr) => attr.indexOf('data-') > -1);

    bodyAttributes.forEach((attr) => document.body.removeAttribute(attr));
  }

  /**
   * Initializes the width settings based on layout configuration
   * @private
   * @param {ILayout} config
   * @return {*}  {void}
   * @memberof LayoutInitService
   */
  private initWidthSettings(config: ILayout): void {
    const pageWidth = config.app?.general?.pageWidth;
    if (!pageWidth || pageWidth === 'default') return;

    // Update containers for header, content, and footer
    this.updateContainerWidth(config.app?.header, pageWidth);
    this.updateContainerWidth(config.app?.content, pageWidth);
    this.updateContainerWidth(config.app?.footer, pageWidth);

    // Update the app configuration and trigger change
    const updatedApp = {
      ...config.app,
      header: config.app?.header,
      content: config.app?.content,
      footer: config.app?.footer,
    };

    this.config.next({ ...this.config.value, app: updatedApp });
  }

  /**
   * Updates the container width for a specific section (header, content, footer)
   * @private
   * @param {*} section
   * @param {string} pageWidth
   * @memberof LayoutInitService
   */
  private updateContainerWidth(section: any, pageWidth: string): void {
    if (section) {
      section.container = pageWidth;
    }
  }
}
