import { Routes } from '@angular/router';
import { ngxPermissionsGuard } from 'ngx-permissions';
import { APP_CONSTANT } from '@constants';

// Re-direct to rule when path permissin not given
const RE_DIRECT_TO_RULE = {
  navigationCommands: [APP_CONSTANT.ERROR_PATH],
  navigationExtras: {
    skipLocationChange: true
  }
};

// Routes
const Routing: Routes = [
  {
    path: 'dashboard',
    data: {
      title: 'Dashboard',
      permissions: {
        only: 'canAccessDashboard',
        redirectTo: RE_DIRECT_TO_RULE
      }
    },
    canActivate: [ngxPermissionsGuard],
    loadChildren: () => import('./../../modules/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'inventory-management',
    data: {
      title: 'Inventory Management',
      permissions: {
        only: 'canAccessInventoryManagement',
        redirectTo: RE_DIRECT_TO_RULE
      }
    },
    canActivate: [ngxPermissionsGuard],
    loadChildren: () => import('./../../modules/inventory-management/inventory-management.module').then(m => m.InventoryManagementModule)
  },
  {
    path: 'orders-management',
    data: {
      title: 'Orders Management',
      permissions: {
        only: 'canAccessOrdersManagement',
        redirectTo: RE_DIRECT_TO_RULE
      }
    },
    canActivate: [ngxPermissionsGuard],
    loadChildren: () => import('./../../modules/order-management/order-management.module').then(m => m.OrderManagementModule)
  },
  {
    path: 'role-permission-management',
    data: {
      title: 'Role & Permission',
      permissions: {
        only: 'canAccessRolePermissionManagement',
        redirectTo: RE_DIRECT_TO_RULE
      }
    },
    canActivate: [ngxPermissionsGuard],
    loadChildren: () => import('./../../modules/role-permisson-management/role-permisson-management.module').then(m => m.RolePermissonManagementModule)
  },
  {
    path: 'register-management',
    data: {
      title: 'Register Management',
      permissions: {
        only: 'canAccessRegisterManagement',
        redirectTo: RE_DIRECT_TO_RULE
      }
    },
    canActivate: [ngxPermissionsGuard],
    loadChildren: () => import('./../../modules/register-management/register-management.module').then(m => m.RegisterManagementModule)
  },
  {
    path: 'authoring',
    data: {
      title: 'Authoring',
      permissions: {
        only: 'canAccessAuthoring',
        redirectTo: RE_DIRECT_TO_RULE
      }
    },
    canActivate: [ngxPermissionsGuard],
    loadChildren: () => import('./../../modules/authoring/authoring.module').then(m => m.AuthoringModule)
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];

export { Routing };
