// Angular
import { Component, OnInit } from '@angular/core';
// Rxjs
import { Observable } from 'rxjs';
// Service
import { ThemeModeService, ThemeModeType } from './theme-mode.service';

@Component({
  selector: 'app-theme-mode-switcher',
  templateUrl: './theme-mode-switcher.component.html',
  styleUrls: ['./theme-mode-switcher.component.scss']
})
export class ThemeModeSwitcherComponent implements OnInit {

  // Public
  mode$: Observable<ThemeModeType>;
  menuMode$: Observable<ThemeModeType>;

  /**
   * Creates an instance of ThemeModeSwitcherComponent.
   * @param {ThemeModeService} modeService
   * @memberof ThemeModeSwitcherComponent
   */
  constructor(private readonly modeService: ThemeModeService) { }

  /**
   * OnInit
   * @memberof ThemeModeSwitcherComponent
   */
  ngOnInit(): void {
    this.mode$ = this.modeService.mode.asObservable();
    this.menuMode$ = this.modeService.menuMode.asObservable();
  }

  /**
   * Switch mode
   * @param {ThemeModeType} _mode
   * @memberof ThemeModeSwitcherComponent
   */
  switchMode(_mode: ThemeModeType): void {
    this.modeService.switchMode(_mode);
  }
}
