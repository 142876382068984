<!-- begin:: Loading Bar -->
<ngx-loading-bar [height]="'4px'"></ngx-loading-bar>
<!-- end:: Loading Bar -->

<!--begin::sidebar mobile toggle-->
<div class="d-flex align-items-center d-lg-none mx-2" title="Show sidebar menu">
  <div class="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_sidebar_mobile_toggle">
    <app-keenicon name="abstract-14" class="fs-1"></app-keenicon>
  </div>
</div>
<!--end::sidebar mobile toggle-->