// Angular
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// Rxjs & Lodash
import { BehaviorSubject } from 'rxjs';
import { get } from 'lodash';
// Config
import { LayoutType, ILayout, CSSClassesType, HTMLAttributesType } from './configs/config';
import { DarkSidebarConfig } from './configs/dark-sidebar.config';
// App Version
import packageInfo from 'package.json';
// Constants
const LAYOUT_CONFIG_LOCAL_STORAGE_KEY = `${packageInfo.version}-layoutConfig`;
const BASE_LAYOUT_TYPE_LOCAL_STORAGE_KEY = `${packageInfo.version}-baseLayoutType`;
const defaultBaseLayoutType: LayoutType = 'dark-sidebar';
const defaultLayoutConfig: ILayout = DarkSidebarConfig;

/**
 * Get Empty HTML Attributes
 * @export
 * @return {*}  {HTMLAttributesType}
 */
export function getEmptyHTMLAttributes(): HTMLAttributesType {
  return {
    asideMenu: {},
    headerMobile: {},
    headerMenu: {},
    headerContainer: {},
  };
}

/**
 * Get Empty Css Classes
 * @export
 * @return {*}  {CSSClassesType}
 */
export function getEmptyCssClasses(): CSSClassesType {
  return {
    header: [],
    headerContainer: [],
    headerMobile: [],
    headerMenu: [],
    aside: [],
    asideMenu: [],
    asideToggle: [],
    toolbar: [],
    toolbarContainer: [],
    content: [],
    contentContainer: [],
    footerContainer: [],
    sidebar: [],
    pageTitle: [],
    wrapper: [],
  };
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService {

  // Observable to track current layout type
  public currentLayoutTypeSubject = new BehaviorSubject<LayoutType | null>(null);

  // Observable to track layout configuration
  public layoutConfigSubject: BehaviorSubject<ILayout> = new BehaviorSubject<ILayout>(
    this.getLayoutConfig(this.getBaseLayoutTypeFromRouteOrLocalStorage())
  );

  // Observable to track CSS classes
  private classes: BehaviorSubject<CSSClassesType> = new BehaviorSubject<CSSClassesType>(getEmptyCssClasses());

  // Observable to track HTML attributes
  private attrs: BehaviorSubject<HTMLAttributesType> = new BehaviorSubject<HTMLAttributesType>(getEmptyHTMLAttributes());

  constructor(private activatedRoute: ActivatedRoute) { }

  /**
   * Retrieves a property from the layout configuration using a given path
   * @param {string} path - The path to the property in the layout config
   * @param {ILayout} [config] - Optional config to use instead of the default one
   * @returns {string | boolean | undefined | Object} - The value found at the specified path
   */
  getProp(path: string, config?: ILayout): string | boolean | undefined | Object {
    return config ? get(config, path) : get(this.layoutConfigSubject.value, path);
  }

  /**
   * Adds CSS classes to a specific path in the layout's CSS class list
   * @param {string} path - The path of the CSS class group
   * @param {string} classesInStr - The CSS classes to add (space-separated string)
   */
  setCSSClass(path: string, classesInStr: string): void {
    const updatedCssClasses = { ...this.classes.value };
    const cssClasses = updatedCssClasses[path] || [];

    classesInStr.split(' ').forEach((cssClass: string) => cssClasses.push(cssClass));
    updatedCssClasses[path] = cssClasses;

    this.classes.next(updatedCssClasses);
  }

  /**
   * Retrieves the list of CSS classes for a specific path
   * @param {string} path - The path of the CSS class group
   * @returns {string[]} - An array of CSS classes
   */
  getCSSClasses(path: string): string[] {
    return this.classes.value[path] || [];
  }

  /**
   * Retrieves CSS classes as a string for a specific path
   * @param {string} path - The path of the CSS class group
   * @returns {string} - The CSS classes as a single string
   */
  getStringCSSClasses(path: string): string {
    return this.getCSSClasses(path).join(' ');
  }

  /**
   * Retrieves HTML attributes for a specific path
   * @param {string} path - The path of the HTML attribute group
   * @returns {{ [attrName: string]: string | boolean }} - An object containing the HTML attributes
   */
  getHTMLAttributes(path: string): { [attrName: string]: string | boolean } {
    return this.attrs.value[path] || {};
  }

  /**
   * Sets an HTML attribute for a specific path
   * @param {string} path - The path of the HTML attribute group
   * @param {string} attrKey - The HTML attribute key
   * @param {string | boolean} attrValue - The value of the HTML attribute
   */
  setHTMLAttribute(path: string, attrKey: string, attrValue: string | boolean): void {
    const updatedAttributes = { ...this.attrs.value };
    const attributesObj = updatedAttributes[path] || {};

    attributesObj[attrKey] = attrValue;
    updatedAttributes[path] = attributesObj;

    this.attrs.next(updatedAttributes);
  }

  /**
   * Retrieves the base layout type, either from the route or local storage
   * @returns {LayoutType} - The base layout type
   */
  getBaseLayoutTypeFromRouteOrLocalStorage(): LayoutType {
    const routeData = this.activatedRoute.firstChild?.snapshot?.data;
    return routeData?.layout || this.getBaseLayoutTypeFromLocalStorage();
  }

  /**
   * Retrieves the base layout type from local storage
   * @returns {LayoutType} - The base layout type
   */
  getBaseLayoutTypeFromLocalStorage(): LayoutType {
    const layoutType = localStorage?.getItem(BASE_LAYOUT_TYPE_LOCAL_STORAGE_KEY) as LayoutType | null;

    if (!layoutType) {
      this.setBaseLayoutType(defaultBaseLayoutType);
      return defaultBaseLayoutType;
    }

    return layoutType;
  }


  /**
   * Retrieves the layout configuration by type
   * @param {LayoutType | undefined} layoutType - The layout type
   * @returns {ILayout} - The layout configuration object
   */
  getLayoutByType(layoutType: LayoutType | undefined): ILayout {
    return layoutType === 'dark-sidebar' ? DarkSidebarConfig : defaultLayoutConfig;
  }

  /**
   * Retrieves the layout configuration, either from local storage or by type
   * @param {LayoutType} layoutType - The layout type
   * @returns {ILayout} - The layout configuration object
   */
  getLayoutConfig(layoutType: LayoutType): ILayout {
    const storedLayoutType = this.getBaseLayoutTypeFromLocalStorage();
    const configInString = localStorage?.getItem(`${layoutType}-${LAYOUT_CONFIG_LOCAL_STORAGE_KEY}`);

    if (configInString) {
      try {
        return JSON.parse(configInString) as ILayout;
      } catch (ex) {
        console.error('Reading config exception:', ex);
      }
    }

    return this.getLayoutByType(layoutType);
  }

  /**
   * Sets the base layout type and saves it to local storage
   * @param {LayoutType} layoutType - The layout type
   */
  setBaseLayoutType(layoutType: LayoutType): void {
    const config = this.getLayoutByType(layoutType);

    localStorage?.setItem(BASE_LAYOUT_TYPE_LOCAL_STORAGE_KEY, layoutType);
    localStorage?.setItem(`${layoutType}-${LAYOUT_CONFIG_LOCAL_STORAGE_KEY}`, JSON.stringify(config));
  }

  /**
   * Saves the base layout configuration to local storage and reloads the page
   * @param {ILayout} config - The layout configuration object
   */
  saveBaseConfig(config: ILayout): void {
    const baseLayoutType = this.getBaseLayoutTypeFromLocalStorage();
    localStorage?.setItem(`${baseLayoutType}-${LAYOUT_CONFIG_LOCAL_STORAGE_KEY}`, JSON.stringify(config));

    document.location.reload();
  }

  /**
   * Resets the base layout configuration by removing it from local storage and reloading the page
   */
  resetBaseConfig(): void {
    const layoutType = this.getBaseLayoutTypeFromLocalStorage();
    localStorage?.removeItem(`${layoutType}-${LAYOUT_CONFIG_LOCAL_STORAGE_KEY}`);

    document.location.reload();
  }

  /**
   * Reinitializes layout properties by resetting CSS classes and HTML attributes
   */
  reInitProps(): void {
    this.classes.next(getEmptyCssClasses());
    this.attrs.next(getEmptyHTMLAttributes());
  }
}
