// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
// Rxjs
import { Observable, Subscription } from 'rxjs';
import { ILayout } from '../../core/configs/config';
import { LayoutService } from '../../core/layout.service';
// Store
import { Store, select } from '@ngrx/store';
import { currentUser, IUserAccount } from 'src/app/core/auth';
import { AppState } from 'src/app/core/reducers';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {

  // Public props
  profile$: Observable<IUserAccount>;
  appSidebarDisplay: boolean;
  appSidebarDefaultFixedDesktop: boolean;
  appSidebarDefaultMinimizeDesktopEnabled: boolean;
  appSidebarDefaultMinimizeDesktopDefault: boolean;
  appSidebarDefaultMinimizeDesktopHoverable: boolean;
  appSidebarDefaultMinimizeMobileEnabled: boolean;
  appSidebarDefaultMinimizeMobileDefault: boolean;
  appSidebarDefaultMinimizeMobileHoverable: boolean;
  appSidebarDefaultCollapseDesktopEnabled: boolean;
  appSidebarDefaultCollapseDesktopDefault: boolean;
  appSidebarDefaultCollapseMobileEnabled: boolean;
  appSidebarDefaultCollapseMobileDefault: boolean;

  appSidebarDefaultPushHeader: boolean;
  appSidebarDefaultPushToolbar: boolean;
  appSidebarDefaultPushFooter: boolean;

  appSidebarDefaultStacked: boolean;

  // Logo
  appSidebarDefaultMinimizeDefault: boolean;
  toggleButtonClass: string;
  toggleEnabled: boolean;
  toggleType: string;
  toggleState: string;

  // Private
  private unsubscribe: Subscription[] = [];

  /**
   * Creates an instance of SidebarComponent.
   * @param {LayoutService} layout
   * @param {Store<AppState>} store
   * @memberof SidebarComponent
   */
  constructor(
    private readonly layout: LayoutService,
    private readonly store: Store<AppState>
  ) { }

  /**
   * On Init
   * @memberof SidebarComponent
   */
  ngOnInit(): void {
    // Get User
    this.profile$ = this.store.pipe(select(currentUser));

    // Get Layout config and update properties
    const subscr = this.layout.layoutConfigSubject.asObservable().subscribe((config: ILayout) => {
      this.updateProps(config);
    });
    this.unsubscribe.push(subscr);
  }

  /**
   * Update Properties
   * @param {ILayout} config
   * @return {*}  {void}
   * @memberof SidebarComponent
   */
  updateProps(config: ILayout): void {
    this.appSidebarDisplay = this.layout.getProp('app.sidebar.display', config) as boolean;
    if (!this.appSidebarDisplay) return;

    this.updateDesktopSidebar(config);
    this.updateMobileSidebar(config);
    this.updateCollapseOptions(config);
    this.updatePushOptions(config);
    this.updateStackedSidebar(config);
    this.updateToggleOptions(config);

    document.body.setAttribute('data-kt-app-sidebar-enabled', 'true');
    document.body.setAttribute('data-kt-app-sidebar-fixed', this.appSidebarDefaultFixedDesktop.toString());
  }

  /**
   * Helper methods for desktop sidebar settings
   * @private
   * @param {ILayout} config
   * @memberof SidebarComponent
   */
  private updateDesktopSidebar(config: ILayout): void {
    this.appSidebarDefaultFixedDesktop = this.layout.getProp('app.sidebar.default.fixed.desktop', config) as boolean;

    this.appSidebarDefaultMinimizeDesktopEnabled = this.layout.getProp('app.sidebar.default.minimize.desktop.enabled', config) as boolean;
    if (this.appSidebarDefaultMinimizeDesktopEnabled) {
      this.appSidebarDefaultMinimizeDesktopDefault = this.layout.getProp('app.sidebar.default.minimize.desktop.default', config) as boolean;
      this.setAttributeConditionally('data-kt-app-sidebar-minimize', 'on', this.appSidebarDefaultMinimizeDesktopDefault);

      this.appSidebarDefaultMinimizeDesktopHoverable = this.layout.getProp('app.sidebar.default.minimize.desktop.hoverable', config) as boolean;
      this.setAttributeConditionally('data-kt-app-sidebar-hoverable', 'true', this.appSidebarDefaultMinimizeDesktopHoverable);
    }
  }

  /**
   * Helper methods for mobile sidebar settings
   * @private
   * @param {ILayout} config
   * @memberof SidebarComponent
   */
  private updateMobileSidebar(config: ILayout): void {
    this.appSidebarDefaultMinimizeMobileEnabled = this.layout.getProp('app.sidebar.default.minimize.mobile.enabled', config) as boolean;
    if (this.appSidebarDefaultMinimizeMobileEnabled) {
      this.appSidebarDefaultMinimizeMobileDefault = this.layout.getProp('app.sidebar.default.minimize.mobile.default', config) as boolean;
      this.setAttributeConditionally('data-kt-app-sidebar-minimize-mobile', 'on', this.appSidebarDefaultMinimizeMobileDefault);

      this.appSidebarDefaultMinimizeMobileHoverable = this.layout.getProp('app.sidebar.default.minimize.mobile.hoverable', config) as boolean;
      this.setAttributeConditionally('data-kt-app-sidebar-hoverable-mobile', 'true', this.appSidebarDefaultMinimizeMobileHoverable);
    }
  }

  /**
   * Collapse settings for desktop and mobile
   * @private
   * @param {ILayout} config
   * @memberof SidebarComponent
   */
  private updateCollapseOptions(config: ILayout): void {
    this.appSidebarDefaultCollapseDesktopEnabled = this.layout.getProp('app.sidebar.default.collapse.desktop.enabled', config) as boolean;
    if (this.appSidebarDefaultCollapseDesktopEnabled) {
      this.appSidebarDefaultCollapseDesktopDefault = this.layout.getProp('app.sidebar.default.collapse.desktop.default', config) as boolean;
      this.setAttributeConditionally('data-kt-app-sidebar-collapse', 'on', this.appSidebarDefaultCollapseDesktopDefault);
    }

    this.appSidebarDefaultCollapseMobileEnabled = this.layout.getProp('app.sidebar.default.collapse.mobile.enabled', config) as boolean;
    if (this.appSidebarDefaultCollapseMobileEnabled) {
      this.appSidebarDefaultCollapseMobileDefault = this.layout.getProp('app.sidebar.default.collapse.mobile.default', config) as boolean;
      this.setAttributeConditionally('data-kt-app-sidebar-collapse-mobile', 'on', this.appSidebarDefaultCollapseMobileDefault);
    }
  }

  /**
   * Push settings for header, toolbar, footer
   * @private
   * @param {ILayout} config
   * @memberof SidebarComponent
   */
  private updatePushOptions(config: ILayout): void {
    if (this.layout.getProp('app.sidebar.default.push')) {
      this.appSidebarDefaultPushHeader = this.layout.getProp('app.sidebar.default.push.header', config) as boolean;
      this.setAttributeConditionally('data-kt-app-sidebar-push-header', 'true', this.appSidebarDefaultPushHeader);

      this.appSidebarDefaultPushToolbar = this.layout.getProp('app.sidebar.default.push.toolbar', config) as boolean;
      this.setAttributeConditionally('data-kt-app-sidebar-push-toolbar', 'true', this.appSidebarDefaultPushToolbar);

      this.appSidebarDefaultPushFooter = this.layout.getProp('app.sidebar.default.push.footer', config) as boolean;
      this.setAttributeConditionally('data-kt-app-sidebar-push-footer', 'true', this.appSidebarDefaultPushFooter);
    }
  }

  /**
   * Stacked sidebar settings
   * @private
   * @param {ILayout} config
   * @memberof SidebarComponent
   */
  private updateStackedSidebar(config: ILayout): void {
    this.appSidebarDefaultStacked = this.layout.getProp('app.sidebar.default.stacked', config) as boolean;
    this.setAttributeConditionally('app-sidebar-stacked', 'true', this.appSidebarDefaultStacked);
  }

  /**
   * Toggle options based on minimize and collapse settings
   * @private
   * @param {ILayout} config
   * @memberof SidebarComponent
   */
  private updateToggleOptions(config: ILayout): void {
    this.appSidebarDefaultMinimizeDefault = this.layout.getProp('app.sidebar.default.minimize.desktop.default', config) as boolean;
    this.toggleButtonClass = this.appSidebarDefaultMinimizeDefault ? 'active' : '';
    this.toggleEnabled = this.appSidebarDefaultMinimizeDesktopEnabled || this.appSidebarDefaultCollapseDesktopEnabled;

    if (this.appSidebarDefaultMinimizeDesktopEnabled) {
      this.toggleType = 'minimize';
      this.toggleState = 'active';
    }

    if (this.appSidebarDefaultCollapseDesktopEnabled) {
      this.toggleType = 'collapse';
      this.toggleState = '';
    }
  }

  /**
   * Helper method to set attributes conditionally
   * @private
   * @param {string} attribute
   * @param {string} value
   * @param {boolean} condition
   * @memberof SidebarComponent
   */
  private setAttributeConditionally(attribute: string, value: string, condition: boolean): void {
    if (condition) {
      document.body.setAttribute(attribute, value);
    }
  }

  /**
   * On Destroy
   * @memberof SidebarComponent
   */
  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
