// Angular
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
// RxJS
import { Subscription } from 'rxjs';
// Services
import { LayoutService } from './core/layout.service';
import { LayoutInitService } from './core/layout-init.service';
// Configs
import { ILayout, LayoutType } from './core/configs/config';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {

  // Public variables
  // Page
  pageContainerCSSClasses: string;

  // Content
  appContentContiner?: 'fixed' | 'fluid';
  appContentContainerClass: string;
  contentCSSClasses: string;
  contentContainerCSSClass: string;

  // Sidebar
  appSidebarDefaultClass: string;
  appSidebarDefaultDrawerEnabled: boolean;
  appSidebarDefaultDrawerAttributes: { [attrName: string]: string } = {};
  appSidebarDisplay: boolean;
  appSidebarDefaultStickyEnabled: boolean;
  appSidebarDefaultStickyAttributes: { [attrName: string]: string } = {};

  // View Child
  @ViewChild('ktSidebar', { static: true }) ktSidebar: ElementRef;
  @ViewChild('ktAside', { static: true }) ktAside: ElementRef;
  @ViewChild('ktHeaderMobile', { static: true }) ktHeaderMobile: ElementRef;
  @ViewChild('ktHeader', { static: true }) ktHeader: ElementRef;

  // Subscription
  private unsubscribe: Subscription[] = [];
  private timmers: Array<null | ReturnType<typeof setTimeout>> = [];

  /**
   * Creates an instance of LayoutComponent.
   * @param {LayoutInitService} initService
   * @param {LayoutService} layout
   * @param {Router} router
   * @param {ActivatedRoute} activatedRoute
   * @memberof LayoutComponent
   */
  constructor(
    private initService: LayoutInitService,
    private layout: LayoutService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    // define layout type and load layout
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {

        const currentLayoutType = this.layout.currentLayoutTypeSubject.value;

        const nextLayoutType: LayoutType =
          this.activatedRoute?.firstChild?.snapshot.data.layout ||
          this.layout.getBaseLayoutTypeFromLocalStorage();

        if (currentLayoutType !== nextLayoutType || !currentLayoutType) {
          this.layout.currentLayoutTypeSubject.next(nextLayoutType);
          this.initService.reInitProps(nextLayoutType);
        }
      }
    });
  }

  /**
   * On Init
   * @memberof LayoutComponent
   */
  ngOnInit() {
    const subscr = this.layout.layoutConfigSubject
      .asObservable()
      .subscribe((config) => {
        this.updateProps(config);
      });
    this.unsubscribe.push(subscr);
  }

  /**
   * Update Props
   * @param {ILayout} config
   * @memberof LayoutComponent
   */
  updateProps(config: ILayout) {

    this.pageContainerCSSClasses =
      this.layout.getStringCSSClasses('pageContainer');

    this.appSidebarDisplay = this.layout.getProp(
      'app.sidebar.display',
      config
    ) as boolean;

    this.contentCSSClasses = this.layout.getStringCSSClasses('content');
    this.contentContainerCSSClass =
      this.layout.getStringCSSClasses('contentContainer');
    this.appContentContiner = this.layout.getProp(
      'app.content.container',
      config
    ) as 'fixed' | 'fluid';
    this.appContentContainerClass = this.layout.getProp(
      'app.content.containerClass',
      config
    ) as string;

    // sidebar
    if (this.appSidebarDisplay) {
      this.updateSidebar(config);
    }
  }

  /**
   * Update Sidebar
   * @param {ILayout} config
   * @memberof LayoutComponent
   */
  updateSidebar(config: ILayout) {
    this.appSidebarDefaultClass = this.layout.getProp(
      'app.sidebar.default.class',
      config
    ) as string;

    this.appSidebarDefaultDrawerEnabled = this.layout.getProp(
      'app.sidebar.default.drawer.enabled',
      config
    ) as boolean;
    if (this.appSidebarDefaultDrawerEnabled) {
      this.appSidebarDefaultDrawerAttributes = this.layout.getProp(
        'app.sidebar.default.drawer.attributes',
        config
      ) as { [attrName: string]: string };
    }

    this.appSidebarDefaultStickyEnabled = this.layout.getProp(
      'app.sidebar.default.sticky.enabled',
      config
    ) as boolean;
    if (this.appSidebarDefaultStickyEnabled) {
      this.appSidebarDefaultStickyAttributes = this.layout.getProp(
        'app.sidebar.default.sticky.attributes',
        config
      ) as { [attrName: string]: string };
    }

    const layoutTimmer = setTimeout(() => {
      const sidebarElement = document.getElementById('kt_app_sidebar');
      // sidebar
      if (this.appSidebarDisplay && sidebarElement) {
        const sidebarAttributes = sidebarElement
          .getAttributeNames()
          .filter((t) => t.indexOf('data-') > -1);
        sidebarAttributes.forEach((attr) =>
          sidebarElement.removeAttribute(attr)
        );

        if (this.appSidebarDefaultDrawerEnabled) {
          for (const key in this.appSidebarDefaultDrawerAttributes) {
            if (this.appSidebarDefaultDrawerAttributes.hasOwnProperty(key)) {
              sidebarElement.setAttribute(
                key,
                this.appSidebarDefaultDrawerAttributes[key]
              );
            }
          }
        }

        if (this.appSidebarDefaultStickyEnabled) {
          for (const key in this.appSidebarDefaultStickyAttributes) {
            if (this.appSidebarDefaultStickyAttributes.hasOwnProperty(key)) {
              sidebarElement.setAttribute(
                key,
                this.appSidebarDefaultStickyAttributes[key]
              );
            }
          }
        }
      }
    }, 0);
    this.timmers.push(layoutTimmer);

  }

  /**
   * On Destroy
   * @memberof LayoutComponent
   */
  ngOnDestroy() {
    this.timmers.forEach((timmer: any) => clearTimeout(timmer));
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
