<!-- begin:: Super admin impersonation mode banner -->
@if (isImpersonating) {
<div class="banner d-flex flex-column flex-lg-row justify-content-center align-items-center lh-sm fixed-top py-2">
  <span class="d-sm-inline-flex align-items-center gap-1 py-2 px-3 rounded-pill masthead-notice">
    @if (loading) {
    <span class="fw-semibold text-black">Please wait while we load your profile. The page will reload automatically.</span>
    } @else {
      <span class="fw-semibold text-black">You are in impersonation mode</span>
      <button type="button" (click)="onCloseImpersonation()" class="btn-close ms-2" aria-label="Close"></button>
    }
  </span>
</div>
}
<!-- end:: Super admin impersonation mode banner -->