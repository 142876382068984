// Angular
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
// Rxjs
import { Subscription } from 'rxjs';
// Configs
import { LayoutType } from '../../../core/configs/config';
// Service
import { LayoutService } from '../../../core/layout.service';

@Component({
  selector: 'app-sidebar-logo',
  templateUrl: './sidebar-logo.component.html',
  styleUrls: ['./sidebar-logo.component.scss'],
})
export class SidebarLogoComponent implements OnInit, OnDestroy {

  // Public
  currentLayoutType: LayoutType | null;
  toggleAttr: string;

  // Private
  private unsubscribe: Subscription[] = [];

  // Input
  @Input() toggleButtonClass: string = '';
  @Input() toggleEnabled: boolean;
  @Input() toggleType: string = '';
  @Input() toggleState: string = '';

  /**
   * Creates an instance of SidebarLogoComponent.
   * @param {LayoutService} layout
   * @memberof SidebarLogoComponent
   */
  constructor(private readonly layout: LayoutService) { }
  /**
   * OnInit
   * @memberof SidebarLogoComponent
   */
  ngOnInit(): void {
    this.toggleAttr = `app-sidebar-${this.toggleType}`;
    const layoutSubscr = this.layout.currentLayoutTypeSubject
      .asObservable()
      .subscribe((layout) => {
        this.currentLayoutType = layout;
      });
    this.unsubscribe.push(layoutSubscr);
  }

  /**
   * OnDestroy
   * @memberof SidebarLogoComponent
   */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
