// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ResolveEnd, Router } from '@angular/router';
// RxJS
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
// Service
import { PageInfoService } from '../../core/page-info.service';
import { LayoutService } from '../../core/layout.service';
// Components
import { ToggleComponent, MenuComponent, ScrollComponent, DrawerComponent } from "../../../kt/components";

@Component({
  selector: 'app-scripts-init',
  templateUrl: './scripts-init.component.html',
})
export class ScriptsInitComponent implements OnInit, OnDestroy {

  // Subscriptions
  private unsubscribe: Subscription[] = [];
  private timmers: Array<null | ReturnType<typeof setTimeout>> = [];

  /**
   * Creates an instance of ScriptsInitComponent.
   * @param {LayoutService} layout
   * @param {PageInfoService} pageInfo
   * @param {Router} router
   * @memberof ScriptsInitComponent
   */
  constructor(
    private layout: LayoutService,
    private pageInfo: PageInfoService,
    private router: Router) {

    // Subscribe to router events
    this.router.events
      .pipe(filter((event) => event instanceof ResolveEnd))
      .subscribe(() => this._handlePageInfo());
  }

  /**
   * On Init
   * @memberof ScriptsInitComponent
   */
  ngOnInit(): void {
    this.pluginsInitialization();
    const layoutUpdateSubscription = this.layout.layoutConfigSubject
      .asObservable()
      .subscribe(() => {
        this.pluginsReInitialization();
      });
    this.unsubscribe.push(layoutUpdateSubscription);
  }

  /**
   * Plugins Initialization
   * @memberof ScriptsInitComponent
   */
  pluginsInitialization() {
    const pluginInitTimmer = setTimeout(() => {
      ToggleComponent.bootstrap();
      DrawerComponent.bootstrap();
      MenuComponent.bootstrap();
      ScrollComponent.bootstrap();
    }, 200);
    this.timmers.push(pluginInitTimmer);

  }

  /**
   * Plugins ReInitialization
   * @memberof ScriptsInitComponent
   */
  pluginsReInitialization(): void {
    const pluginInitTimmer = setTimeout(() => {
      ToggleComponent.reinitialization();
      DrawerComponent.reinitialization();
      MenuComponent.reinitialization();
      ScrollComponent.reinitialization();
    }, 100);
    this.timmers.push(pluginInitTimmer);
  }

  /**
   * On Destroy
   * @memberof ScriptsInitComponent
   */
  ngOnDestroy() {
    this.timmers.forEach((timmer: any) => clearTimeout(timmer));
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  /**
   * Set Page Title and Breadcrumbs
   * @private
   * @memberof ScriptsInitComponent
   */
  private _handlePageInfo(): void {
    const pageInfoTimmer = setTimeout(() => {
      this.pageInfo.calculateTitle();
      this.pageInfo.calculateBreadcrumbs();
    }, 10);
    this.timmers.push(pageInfoTimmer);
  }
}
