<!--begin::Footer container-->
<div class="app-container app-container d-flex flex-column flex-md-row flex-center flex-md-stack py-3 container-fluid">
  <!--begin::Copyright-->
  <div class="text-dark order-2 order-md-1">
    <span class="text-muted fw-semibold me-1">{{currentDateStr}} &copy;</span>
    <span class="text-gray-800 text-hover-primary">Prime Version {{appVersion}}</span>
  </div>
  <!--end::Copyright-->

  <!--begin::Menu-->
  <ul class="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
    <li class="menu-item"><a href="#" class="menu-link px-2">Support</a></li>

    <li class="menu-item" *ngxPermissionsOnly="['canReportIssue']">
      <a href="javascript:;" (click)="openSentryDialog()" class="menu-link px-2">Report Isssue</a>
    </li>
  </ul>
  <!--end::Menu-->
</div>
<!--end::Footer container-->